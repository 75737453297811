<template>
  <v-container>
    <v-row justify="center">
      <v-col
        v-for="(moduleCard, i) in moduleCards"
        :key="i"
        cols="12"
        sm="12"
        md="6"
        lg="3"
        class="text-center"
      >
        <img :src="moduleCard.image" width="150"  alt=""/>
        <v-card
          class="d-flex align-center text-center"
          height="200"
          outlined
          @click="goTo(moduleCard)"
          :disabled="checkActiveStatus(moduleCard.moduleId)"
        >
          <v-card-text class="text-h6">
            {{ moduleCard.label }}
          </v-card-text>
        </v-card>
      </v-col>
      <div>
        <modal-component v-show="modalOpen" @close-modal="modalOpen=false" :module-label="moduleLabel" :module-link="moduleLink"/>
      </div>
    </v-row>
    <v-row justify="center">
        <accordion-component></accordion-component>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModalComponent from "@/components/common/Modal";
import AccordionComponent from "@/components/user/AccordionManageAccount";
// import stripeApi from "@/api/stripe";

export default {
  components: {
    ModalComponent,
    AccordionComponent,
  },
  data() {
    return {
      moduleCards: [
        {
          label: "Know Your Customer",
          image: require("../../assets/Allec-New-Logo-K.png"),
          link: "http://allec.io/know-your-customer",
          moduleId: process.env.VUE_APP_MARKETPLACE_ID,
          isMultiTenant: false,
        },
        {
          label: "Inventory Management",
          image: require("../../assets/Allec-New-Logo-I.png"),
          link: "http://allec.io/inventory-management",
          moduleId: process.env.VUE_APP_INVENTORY_ID,
          isMultiTenant: true,
        },
        {
          label: "Warehouse Management",
          image: require("../../assets/Allec-New-Logo-W.png"),
          link: "http://allec.io/warehouse-management",
          moduleId: process.env.VUE_APP_WMS_ID,
          isMultiTenant: true,
        },
        {
          label: "Marketplace",
          image: require("../../assets/Allec-New-Logo-M.png"),
          link: "http://allec.io/marketplace",
          moduleId: process.env.VUE_APP_MARKETPLACE_ID,
          isMultiTenant: false,
        },
      ],
      modalOpen: false,
      moduleLabel: null,
      moduleLink: null,
    };
  },
  computed: {
    ...mapGetters(["DPRBaseUrl",
                  "sessionData",
                  "currentTenant",
                  "tenantFormData",
                  "currentUserType",
                  "isInventorySubscriptionActive",
                  "isWarehouseSubscriptionActive",
                  "isMarketPlaceSubscriptionActive"]),


  },
  mounted() {
      const stateTenant = this.$store.getters.sessionData.tenant;

      if (stateTenant != null) {
          this.fetchCurrentTenant(stateTenant.id);
          this.getActiveSubscriptions(stateTenant.id);
      } else if (this.currentUserType.key === 'master_admin') {
        this.$store.commit({
          type: "marketplaceSubscriptionStatus",
          active: true
        });

        this.$store.commit({
          type: "marketplaceTotalSubscriptions",
          total: 1000
        });

        this.$store.commit({
          type: "inventorySubscriptionStatus",
          active: true
        });

        this.$store.commit({
          type: "inventoryTotalSubscriptions",
          total: 1000
        });

        this.$store.commit({
          type: "warehouseSubscriptionStatus",
          active: true
        });

        this.$store.commit({
          type: "warehouseTotalSubscriptions",
          total: 1000
        });
      }

      this.getSubscriptionsInfo();
  },


  methods: {
    ...mapActions(["getTenantsById","fetchCurrentTenant", "getTenantActiveSubscriptions", "getSubscriptionsData"]),

    checkActiveStatus(moduleId) {
      switch (moduleId) {
        case '1':
          return !this.isInventorySubscriptionActive;

        case '2':
          return !this.isWarehouseSubscriptionActive;

        case '3':
          return !this.isMarketPlaceSubscriptionActive;

        default:
          return true;
      }
    },

    getSubscriptionsInfo() {
      this.getSubscriptionsData().catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Error fetching the Subscriptions Data",
          toast: true,
          position: "bottom-end",
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    },

    getActiveSubscriptions(tenantId) {
      this.dataToSend = {
        tenant_id: tenantId,
      };

      this.getTenantActiveSubscriptions(this.dataToSend).catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Error fetching tenant Subscriptions",
          toast: true,
          position: "bottom-end",
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    },

    goTo(module = null) {
      if (!module) return;
      const self = this;
      this.moduleLabel = module.label;
      this.moduleLink = module.link;

      if (self.tenantFormData.im_data.isEnabled === false && module.label === "Inventory Management") {
        this.openModal();
        return;
      }
      else if (self.tenantFormData.wms_data.isEnabled === false && module.label === "Warehouse Management") {
        this.openModal();
        return;
      }
      else if(self.tenantFormData.mp_data.isEnabled === false && module.label === "Marketplace") {
        this.openModal();
        return;
      }
      else if(module.label === "Know Your Customer") {
        // self.tenantFormData.mp_data.isEnabled === false
        this.openModal();
        return;
      }

      let httpText = '';

      if (process.env.VUE_APP_SSL == 'true') {
        httpText = 'https://'
      } else {
        httpText = 'http://'
      }

      const actualModule = self.currentTenant.modules.find(mod => mod.id == module.moduleId);

      if (module.isMultiTenant) {
        if (!actualModule) {
          return;
        }

        const url = httpText + actualModule.pivot.domain + "." + actualModule.main_domain;

        location.href = url;
      } else {
        location.href = httpText + actualModule.main_domain;
      }
    },
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>

<style>
</style>