<template>
  <div class="modal-overlay" @click.prevent="toClose()">
    <div class="modal" @click.stop>
      <i class="fas fa-times" @click.prevent="toClose()"></i>
      <img class="modal-img" alt="Allec Logo" src="../../assets/Allec-New-Logo-A.png"/>
      <h6>{{ $t("modal.access") }}  {{moduleLabel}}.</h6>
      <p>{{ $t("modal.information") }}.</p>
      <button @click.prevent="toLink(moduleLink); toClose()">{{ $t("modal.buttonInformation") }}</button>
      <button class="btn" @click.prevent="toClose()">{{ $t("modal.buttonBacktoPage") }}</button>
    </div>
    <div class="close" @click.prevent="toClose()">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    moduleLabel: String,
    moduleLink: String
  },
  methods: {
    toLink(moduleLink) {
      window.open(moduleLink, '_blank');
    },
    toClose() {
      this.$emit('close-modal');
    }
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  z-index: 15;
}
.modal {
  position: relative;
  text-align: center;
  background-color: white;
  height: 320px;
  width: 500px;
  margin-top: 150px;
  border-radius: 20px;
  z-index: 15;
}
.close {
  position: relative;
  margin: 10% 0 0 16px;
  cursor: pointer;
  z-index: 15;
}
.modal-img {
  position: relative;
  width: 120px;
  margin-top: 0;
  z-index: 15;
}
h6 {
  position: relative;
  font-weight: 500;
  font-size: 28px;
  margin: 10px 0;
  z-index: 15;
}
p {
  position: relative;
  font-size: 15px;
  margin: 20px 0;
  z-index: 15;
}
.fas {
  position: relative;
  color: #36304e;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 450px;
  z-index: 15;
}
button {
  position: relative;
  background-color: #5bae95;
  width: 150px;
  height: 40px;
  color: #36304e;
  font-size: 16px;
  border-radius: 16px;
  margin-top: 0;
  z-index: 15;
}
.btn {
  position: relative;
  background-color: #5bae95;
  width: 150px;
  height: 40px;
  color: #36304e;
  font-size: 16px;
  border-radius: 16px;
  margin-left: 50px;
  z-index: 15;
}
</style>