<template>
  <section class="common-section">
    <home-menu-grid></home-menu-grid>
  </section>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import { mapActions } from "vuex";
import HomeMenuGrid from "../components/home/MenuGrid.vue";
export default {
  data() {
    return {
      name: null,
      menuItems: []
    };
  },
  name: "Home",
  components: {
    HomeMenuGrid
  },
  beforeRouteEnter(to, from, next) {
    userApi.fetchUserCallback((response) => {
      next((self) => self.fetchUserData(response));
    });
  },
  // Component already loaded and route changes
  beforeRouteUpdate(to, from, next) {
    const self = this;

    userApi.fetchUserData((response) => {
      if (response && response.data && response.data.id) {
        /* Local Method */
        self.fetchUserData(response);
      }
      next();
    });
  },
  beforeMount(){

  },
  methods: {
    ...mapActions(["getAllProducts"]),
    fetchUserData(promise = null) {
      const self = this;
      self.$nextTick(() => {
        if (!promise) return;
        promise
          .then((suss) => {
            if (!suss.data.id) return;

            self.$store.commit("setUserData", suss.data);
            self.$store.dispatch("fetchUserProfile");
            self.$store.dispatch("getCart");
            self.$store.dispatch("getFavorites");
          })
          .catch((err) => {
              this.$swal.fire({
                  timer: 4000,
                  icon: "error",
                  title: "Error Searching Users Data",
                  text: err,
                  toast: true,
                  position: "bottom-end",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  showCloseButton: true,
              });
          })
          .finally(() => {});
      });
    },
  },
  created() {
    //this.getAllProducts();
  },
};
</script>
<style scoped>
</style>
